/**
 * An enumeration of the events that can be emitted by the WalletApi.
 */
export var EventType;
(function (EventType) {
    EventType["AccountChanged"] = "accountChanged";
    EventType["AccountDisconnected"] = "accountDisconnected";
    EventType["ChainChanged"] = "chainChanged";
})(EventType || (EventType = {}));
export var SchemaType;
(function (SchemaType) {
    SchemaType["Module"] = "module";
    SchemaType["Parameter"] = "parameter";
})(SchemaType || (SchemaType = {}));
